import React, { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('ru');

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const oldLanguage = localStorage.getItem('language');
        let newLanguage;

        if (location.search) {
            const query = location.search;
            const json = {};
            query.replace("?", "").split("&").forEach(pair => {
                const [key, value] = pair.split("=");
                json[key] = decodeURIComponent(value || "");
            });

            if (json?.lang) {
                newLanguage = json.lang;
            }
        }

        if (newLanguage === 'ru' || newLanguage === 'en') {
            setLanguage(newLanguage);
            localStorage.setItem('language', newLanguage);
            navigate('/');
        } else if (oldLanguage === 'ru' || oldLanguage === 'en') {
            setLanguage(oldLanguage);
        }
    }, [location, navigate, setLanguage]);

    const toggleLanguage = () => {
        setLanguage(prevLanguage => (prevLanguage === 'ru' ? 'en' : 'ru'));
        localStorage.setItem('language', language === 'ru' ? 'en' : 'ru');
        navigate('/');
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
