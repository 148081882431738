import React, { memo} from 'react';
import styles from './styles/OurChannels.module.css';

const OurChannels = ({ language }) => {
  const channels = {
    'ru': [
      {
        name: 'Удивительные Места',
        theme: 'Путешествия',
        image: 'ru_travel1.jpeg',
        link: 'https://t.me/+dRx5NrwOr5BhNWNi',
      },
      {
        name: 'Крипто Дейли',
        theme: 'Криптовалюты',
        image: 'ru_crypto1.jpeg',
        link: 'https://t.me/+94da_K8HTpk2MGQy',
      },
      {
        name: 'Let’s talk about IT',
        theme: 'Путешествия',
        image: 'ru_it1.jpeg',
        link: 'https://t.me/+SqQN5DuGxl80MTli',
      },
      {
        name: 'Дубай Дейли',
        theme: 'Путешествия',
        image: 'ru_travel4.jpeg',
        link: 'https://t.me/+-KA1psGJBTRkY2Iy',
      },
      {
        name: 'Удивительные Места Европы',
        theme: 'Путешествия',
        image: 'ru_travel3.jpeg',
        link: 'https://t.me/+Tmvp95LvpMM1Njdi',
      },
      {
        name: 'TechFront: Истории IT-успеха',
        theme: 'Business',
        image: 'ru_business1.jpeg',
        link: 'https://t.me/+zDjxGigOJ89jZDA6',
      },
    ],
    'en': [
      {
        name: 'Travel with AI',
        theme: 'Travel',
        image: 'en_travel1.jpeg',
        link: 'https://t.me/+LRAfxJOQ6ng4OGRi',
      },
      {
        name: 'TechFront: IT Success Stories',
        theme: 'Business',
        image: 'en_business1.jpeg',
        link: 'https://t.me/+VtPH23hbJvg2MWFi',
      },
    ],
  }

  return (
    <div className={styles.channels}>
      {language === 'ru' && (
        <>
          <h3 className={styles.title}>Русские каналы</h3>
          <div className={styles.channelsList}>
            {channels['ru'].map((channel, i) => (
              <div key={i} className={styles.channel} onClick={() => window.open(`${channel.link}`, '_blank')}>
                <img src={`../../images/channels/${channel.image}`} alt={channel.name} className={styles.channelImage} />
                <h4 className={styles.channelName}>{channel.name}</h4>
              </div>
            ))}
          </div>
          <h3 className={styles.title}>Английские каналы</h3>
          <div className={styles.channelsList}>
            {channels['en'].map((channel, i) => (
              <div key={i} className={styles.channel} onClick={() => window.open(`${channel.link}`, '_blank')}>
                <img src={`../../images/channels/${channel.image}`} alt={channel.name} className={styles.channelImage} />
                <h4 className={styles.channelName}>{channel.name}</h4>
              </div>
            ))}
          </div>
        </>
      )}

      {language === 'en' && (
        <>
          <h3 className={styles.title}>English channels</h3>
          <div className={styles.channelsList}>
            {channels['en'].map((channel, i) => (
              <div key={i} className={styles.channel} onClick={() => window.open(`https://t.me/${channel.link}`, '_blank')}>
                <img src={`../../images/channels/${channel.image}`} alt={channel.name} className={styles.channelImage} />
                <h4 className={styles.channelName}>{channel.name}</h4>
              </div>
            ))}
          </div>
          <h3 className={styles.title}>Russian channels</h3>
          <div className={styles.channelsList}>
            {channels['ru'].map((channel, i) => (
              <div key={i} className={styles.channel} onClick={() => window.open(`https://t.me/${channel.link}`, '_blank')}>
                <img src={`../../images/channels/${channel.image}`} alt={channel.name} className={styles.channelImage} />
                <h4 className={styles.channelName}>{channel.name}</h4>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(OurChannels);
