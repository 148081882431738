import React, { useCallback, memo } from 'react';
import { submitApplication } from './api';
import styles from './styles/Form.module.css';

import translations from './language/LandingContainer.translations.js';

const Form = ({ language }) => {
  const t = translations[language];

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      telegram: event.target.telegram.value,
      price: event.target.price.value,
      comment: event.target.comment.value,
    };

    try {
      const response = await submitApplication(formData);
      if (response.status === 200) {
        alert(language === "ru" ? "Заявка успешно отправлена!" : "Application has been sent successfully!");
      }
    } catch (error) {
      console.error('Ошибка при отправке формы:', error);
    }
  }, [language]);

  return (
    <div className={styles.contactForm}>
      <form id="contact-form" className={styles.formBox} onSubmit={handleSubmit}>
        <div className={styles.formColumns}>
          <div className={styles.formColumn}>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="name" className={styles.fieldLabel}>{t.formName}</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="name"
                placeholder={t.formNamePlaceholder}
                type="text"
                id="name"
                required
              />
            </div>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="telegram" className={styles.fieldLabel}>{t.formTelegram}</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="telegram"
                placeholder="@username"
                type="text"
                id="telegram"
                required
              />
            </div>
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="email" className={styles.fieldLabel}>Email</label>
              <input
                className={styles.formInput}
                maxLength="256"
                name="email"
                placeholder={t.formEmailPlaceholder}
                type="email"
                id="email"
                required
              />
            </div>
            <div className={styles.formFieldWrapper}>
              <label htmlFor="price" className={styles.fieldLabel}>{t.formPrice}</label>
              <select id="price" name="price" className={styles.formInput} required>
                {language === "ru" ? (
                  <>
                    <option value="">Выберите...</option>
                    <option value="1000">1 000 руб/мес</option>
                    <option value="2000">2 000 руб/мес</option>
                    <option value="5000">5 000 руб/мес</option>
                    <option value="10000">10 000 руб/мес</option>
                    <option value="20000">20 000 руб/мес</option>
                    <option value="50000">50 000 руб/мес</option>
                  </>
                ) : (
                  <>
                    <option value="">Select one...</option>
                    <option value="20">$20/month</option>
                    <option value="50">$50/month</option>
                    <option value="100">$100/month</option>
                    <option value="200">$200/month</option>
                    <option value="500">$500/month</option>
                    <option value="990">$990/month</option>
                  </>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className={styles.formFieldWrapper}>
          <label htmlFor="comment" className={styles.fieldLabel}>{t.formComment}</label>
          <textarea
            id="comment"
            name="comment"
            maxLength="5000"
            placeholder={t.formCommentPlaceholder}
            className={styles.formInput}
          ></textarea>
        </div>
        <input type="submit" value={t.formButton} className={styles.submitButton} />
      </form>
    </div>
  );
};

export default memo(Form);
