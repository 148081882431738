import axios from 'axios';

const apiApp = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/app`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const submitApplication = formData => {
  return apiApp.post('/apply', formData);
};
