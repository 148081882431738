import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { LanguageProvider } from './LanguageContext';

// langind
import LandingContainer from '../landing/LandingContainer';

const AppRoutes = () => {
  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<LandingContainer />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LanguageProvider>
  );
};

export default AppRoutes;
