
const translations = {
    ru: {
        subtitle: "Сервис для автоматической публикации постов и новостей в Telegram каналах на основе искусственного интеллекта",
        ctaButtonText: "Оставить заявку",

        secondBlockFirst: "Telegram AI - это сервис, который позволяет автоматически публиковать посты и новости в Telegram каналах.",
        secondBlockSecond: "Сервис использует искусственный интеллект для генерации текста, имеет функциональность поиска информации в интернете, поддерживает разные языки, может генерировать картинки для постов и многое другое.",
        secondBlockThird: "А также предоставляет возможность настройки публикации постов по расписанию.",

        ourChannels: "Наши каналы",

        formTitle: "Оставьте заявку",
        formName: "Имя",
        formNamePlaceholder: "Ваше имя",
        formTelegram: "Telegram для связи",
        formEmailPlaceholder: "Ваш email",
        formPrice: "Сколько вы готовы заплатить за это решение?",
        formComment: "Комментарий (по желанию)",
        formCommentPlaceholder: "Ваш комментарий",
        formButton: "Отправить",
        formSubtitle: "На данный момент бот работает в тестовом режиме. Но если вы хотите попробовтаь его функционал, оставьте заявку и мы свяжемся с вами.",
    },
    en: {
        subtitle: "Service for automatic publication of posts and news in Telegram channels based on artificial intelligence",
        ctaButtonText: "Get in touch",

        secondBlockFirst: "Telegram AI is a service that allows you to automatically publish posts and news in Telegram channels.",
        secondBlockSecond: "The service uses artificial intelligence to generate text, has the functionality of searching for information on the Internet, supports different languages, can generate images for posts and much more.",
        secondBlockThird: "It also provides the ability to schedule post publication.",

        ourChannels: "Our channels",

        formTitle: "Get in touch",
        formName: "Name",
        formNamePlaceholder: "Enter your name",
        formTelegram: "Telegram for communication",
        formEmailPlaceholder: "Enter your email",
        formPrice: "How much are you willing to pay for this solution?",
        formComment: "Comment (optional)",
        formCommentPlaceholder: "Enter your comment",
        formButton: "Send Request",
        formSubtitle: "At the moment, the bot is working in test mode. But if you want to try its functionality, leave a request and we will contact you.",
    },
};

export default translations;
