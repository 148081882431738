import React, { memo} from 'react';
import styles from './styles/Footer.module.css';

const Footer = () => {
  // const ggsiteLink = 'http://ggsite.ru/';
  const ggsiteLink = "";

  return (
    <div className={styles.footer}>
      {ggsiteLink && <p className={styles.footerText}>designed by <a className={styles.footerLink} href={ggsiteLink} target="_blank" rel="noreferrer">ggsite</a></p>}
      {!ggsiteLink && <p className={styles.footerText}>designed by <span className={styles.footerLink}>ggsite</span></p>}
    </div>
  );
};

export default memo(Footer);
