import React, { useContext, useCallback, useEffect, useState, memo } from "react";

import ScrollColorChange from './ScrollColorChange';
import OurChannels from './OurChannels';
import Footer from './Footer';
import Form from './Form';

import { LanguageContext } from '../routes/LanguageContext.js';
import translations from './language/LandingContainer.translations.js';

import styles from './styles/LandingContainer.module.css';

const tgChannelLink = "https://t.me/+BQrk17YZfcU4ZmNi";

const LandingContainer = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const t = translations[language];

  const [isVisible, setIsVisible] = useState(true);

  const scrollToForm = useCallback(() => {
    const formElement = document.getElementById("request");
    if(formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100;
      setIsVisible(window.scrollY < threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.landingPage}>
      <div className={styles.buttonsContainer}>
        <button onClick={toggleLanguage} className={styles.languageButton} style={{ opacity: isVisible ? 1 : 0 }}>{language}</button>
        <a onClick={scrollToForm} href="#requestForm" className={styles.ctaButton}>{t.ctaButtonText}</a>
      </div>

      <div className={styles.firstBlock}>
        <img src="../../images/site/logo_black.png" alt="ggsite logo" className={styles.logo} />
        <div className={styles.titleBox}>
          <h1 className={styles.title}>Telegram</h1>
          <img
            src="../../images/site/bot.jpeg"
            alt="bot"
            className={styles.titleBot}
            onClick={() => window.open(tgChannelLink, "_blank")}
          />
          <h1 className={styles.title}>AI</h1>
        </div>
        <p className={styles.subtitle}>{t.subtitle}</p>
        <a onClick={scrollToForm} href="#requestForm" className={styles.requestButton}>{t.ctaButtonText}</a>
      </div>

      <div className={styles.secondBlock}>
        <ScrollColorChange text={t.secondBlockFirst} />
        <ScrollColorChange text={t.secondBlockSecond} />
        <ScrollColorChange text={t.secondBlockThird} />
      </div>

      <div className={styles.thirdBlock}>
        <h2 className={styles.thirdBlockTitle}>{t.ourChannels}</h2>
        <OurChannels language={language} />
      </div>

      <div className={styles.formBlock} id="requestForm">
        <h2 className={styles.thirdBlockTitle}>{t.formTitle}</h2>
        <p className={styles.formSubtitle}>{t.formSubtitle}</p>
        <Form language={language} />
      </div>

      <Footer />
    </div>
  );
};

export default memo(LandingContainer);
